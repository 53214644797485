<template>
  <div class="bg" :style="imgUrl">
    <div class="class--con">
      <class-but @forward="forward" @back="backOf" />
      <div class="class--rq">&lt;1{{ className }}></div>
      <div class="login_qrcode_content">
        <qrcode-vue  :value="url" :size="150" level="H"/>
        <div>家长签到</div>
      </div>
    </div>
  </div>
</template>

<script>
import ClassBut from "@/views/conference/ConferenceItem/classBut.vue";
import service from "@/utils/axios";
import QrcodeVue from 'qrcode.vue'
export default {
  name: "reportClass",
  components: {ClassBut,QrcodeVue},
  data() {
    return {
      className: null,
      imgUrl: {},
      url:'',
    }
  },
  created() {
    this.className = this.$route.query.name
    this.getApireportTopic()
    this.url = 'http://parent.dawei.art/getSignInCode.html?classId=' + this.$route.query.classId
    // if (process.env.NODE_ENV === 'development') {
    //   this.url = 'http://testpc.dawei.art:1548/getSignInCode.html?classId=' + this.$route.query.classId
    // } else if (process.env.NODE_ENV === 'production') {
    //   if (process.env.VUE_APP_TITLE === 'test') {
    //     this.url = 'http://testpc.dawei.art:1548/getSignInCode.html?classId=' + this.$route.query.classId
    //   } else if (process.env.VUE_APP_TITLE === 'build') {
    //     this.url = 'http://testpc.dawei.art:1548/getSignInCode.html?classId=' + this.$route.query.classId
    //   }
    // }
    console.log(this.url)
  },
  methods: {
    forward() {
      this.$router.replace({
        name: 'PageFirst',
        query: this.$route.query,
      })
    },
    backOf(){
      this.$router.back()
    },
    async getApireportTopic() {
      let r = await service.post('/dwart/an_teaching/hbk/v1/search/coverImg', {
        classId: this.$route.query.classId,
      })
      this.imgUrl = {backgroundImage: `url("${r.imgUrl}")`}
    },
  },
}
</script>
<style lang="less" scoped>
@import "reportClass.less";

.class--rq {
  width: 281px;
  height: 54px;
  border-radius: 32px;
  box-shadow: 0px 3px 6px rgb(0 0 0 / 20%);
  background-color: #ffffff;
  position: absolute;
  right: 432px;
  bottom: 22px;
  font-size: 36px;
  color: #666666;
  text-align: center;
  line-height: 54px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.login_qrcode_content{
  position: absolute;
  bottom: 10px;
  right: 200px;
  text-align: center;
  color: #2b3654;
  font-size: 24px;
  //增加阴影
  padding: 10px;
  box-shadow: 0px 3px 6px rgb(0 0 0 / 20%);
  border-radius: 10px;
  background-color: #FFFFFF;
}
</style>
